import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table, Spin, Pagination, Tooltip, Modal, Tabs, Checkbox, Button } from "antd";
import DashboardMap from "./DashboardMap";
import EvelatorList from "./EvelatorList";
import DashboardFilter from "./DashboardFilter";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  DownSquareOutlined,
  UpSquareOutlined,
} from "@ant-design/icons";
import {
  elevatorUtilizationtableColumns,
  elevatorUtilizationtabledata,
} from "./DashboardData";
import moment from "moment";
import DemoPie from "../../common/DemoPie";
import "./Styles.css";
import { useNavigate } from "react-router";
import { RTMBaseUrl, AlertBaseUrl, baseUrl } from "../../common/BaseUrl";
import axios from "axios";
import BarChartDashboard from "./BarChartDashboard";
import axiosRetry from "axios-retry";

const Dashboard = () => {
  const [mostLeastkey, setMostLeastkey] = useState(1);
  // const [mostButton, setMostButton] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [paginationCount, setPagiationCount] = useState();
  const [elevatorStatus, setelEvatorStatus] = useState();
  const [statusLoader, setStatusLoader] = useState(true);
  // const [elevatormostusedData, setElevatormostusedData] = useState();
  // const [elevatorleastusedData, setElevatorleastusedData] = useState();
  const [utilizationTableDatasource, setUtilizationTableDatasource] = useState();
  const [elevatorUtilization, setElevatorUtilization] = useState();
  const [elevatorLoader, setElevatorLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardMapData, setDashboardMapData] = useState();
  const [utilizationData, setUtilizationData] = useState();
  const [selectedDates, setSelectedDates] = useState([]);
  const [headerFilter, setHeaderFilter] = useState({
    Daily: true,
    Weekly: false,
    Monthly: false,
  });
  const [utilizedLoader, setUtilizedLoader] = useState(false);
  const [alertDashboard, setAlertDashboard] = useState();
  const [alertBarDashboard, setAlertBarDashboard] = useState();
  const navigate = useNavigate();
  const accessToken = window.localStorage.getItem("accessToken");
  const userProfile = JSON.parse(window.localStorage.getItem("profile"));
  const showEula = JSON.parse(window.localStorage.getItem("SHOW_EULA")) || false;
  const userEmail = window.localStorage.getItem("userEmail");
  const electricaraVisible = userProfile?.ONLY_ELECTRICAL_PARAMETER;
  const deviceType = window.localStorage.getItem("devicetype");
  const [UaModalOpen, setIsUaModalOpen] = useState(showEula);
  const [UserAccepted, setIsUserAccepted] = useState(false);
  let currentT = moment().format("HH:mm:ss");

  const handleAgree = () => {
    axios.post(`${baseUrl}/eula`,
      { email: userEmail },
      { headers: { ["x-access-token"]: accessToken } }
    ).then((response) => {
      if (response?.status === 200) {
        setIsUaModalOpen(false);
        window.localStorage.setItem("SHOW_EULA", false);
      }
    });
  };

  const handleDisagree = () => {
    setIsUaModalOpen(false);
    window.localStorage.clear();
    navigate("/");
  };

  const toggleAgree = () => {
    setIsUserAccepted(!UserAccepted);
  };

  const modalTabs = [{
    key: '1',
    label: 'EULA',
    children: <div style={{ height: "70vh" }}><iframe src="https://v2.bosch-connected-elevators.com/static/media/09102023_EULA_Terms_revised_V1.c7863e4e49a0517be019.pdf" style={{ width: "100%", height: "100%" }} frameBorder="0" ></iframe></div>
  },
  {
    key: '2',
    label: 'EULA (ಕನ್ನಡ)',
    children: <div style={{ height: "70vh" }}><iframe src="https://v2.bosch-connected-elevators.com/static/media/09102023_EULA_Terms_revised_V1.c7863e4e49a0517be019.pdf" style={{ width: "100%", height: "100%" }} frameBorder="0" ></iframe></div>
  },
  {
    key: '3',
    label: 'INSTALLATION MANUAL',
    children: <div style={{ height: "70vh" }}><iframe src="https://v2.bosch-connected-elevators.com/static/media/09142023_Phantom_Installation%20Manual_Final.702f78d29ab85c2f6b99.pdf" style={{ width: "100%", height: "100%" }} frameBorder="0" ></iframe></div>
  },
  {
    key: '4',
    label: 'INSTALLATION MANUAL (ಕನ್ನಡ)',
    children: <div style={{ height: "70vh" }}><iframe src="https://v2.bosch-connected-elevators.com/static/media/Edge_Installation%20Manual_KAN_Revised.b58d8915ff24a4389e34.pdf" style={{ width: "100%", height: "100%" }} frameBorder="0" ></iframe></div>
  }
  ]

  const alertNotificationcol = [
    {
      title: `${deviceType}`,
      dataIndex: "DEVICE_NAME",
      key: "name",
      align: "left",
    },
    {
      title: "Alert",
      dataIndex: "DESCRIPTION",
      key: "details",
      align: "center",
    },
    {
      title: "Time",
      dataIndex: "CREATED_ON",
      key: "time",
      align: "center",
    },
    {
      title: "Severity",
      dataIndex: "PRIORITY",
      key: "severity",
      align: "center",
      render: (PRIORITY) => {
        if (PRIORITY?.toUpperCase() === "HIGH") {
          return <div className="HighSeviority center">High</div>;
        } else if (PRIORITY?.toUpperCase() === "MEDIUM") {
          return <div className="MediumSeviority center">Medium</div>;
        } else if (PRIORITY?.toUpperCase() === "LOW") {
          return <div className="LowSeviority center">Low</div>;
        } else {
          return <div>NF</div>;
        }
      },
    },
  ];

  const mostUtilizationCol = [
    {
      title: `${deviceType} Name`,
      dataIndex: "DEVICE_NAME",
      key: "DEVICE_NAME",
      align: "center",
    },
    deviceType === "Elevator" ?
      {
        title: "No. of Floors",
        dataIndex: "FLOOR",
        key: "FLOOR",
        align: "center",
      } : null,
    {
      title: "Energy Consumption (kWh)",
      dataIndex: "ENERGY" || "-",
      key: "ENERGY",
      align: "center",
    },
    {
      title: "Active Hours(HH:MM)",
      dataIndex: "ACTIVE",
      key: "ACTIVE",
      align: "center",
    },
  ].filter(Boolean);
  useEffect(() => {
    axiosRetry(axios, {
      retries: 3,
      retryDelay: (retryCount) => {
        return retryCount * 1000;
      },
      retryCondition: (error) => error.message === "Network Error",
    });
    setHeaderFilter({ Daily: true, Weekly: false, Monthly: false });
  }, []);

  useEffect(() => {
    axios
      .post(
        `${AlertBaseUrl}/summary`,
        {},
        {
          headers: { ["x-access-token"]: accessToken },
        }
      )
      .then((response) => {
        setAlertDashboard(response?.data?.data);
      });
  }, []);

  useEffect(() => {
    axios
      .post(
        `${AlertBaseUrl}/graphSummary`,
        {
          ONLY_ELECTRICAL_PARAMETER: electricaraVisible
        },
        {
          headers: { ["x-access-token"]: accessToken },
        }
      )
      .then((response) => {
        setAlertBarDashboard(response?.data?.data?.[0]);
      });
  }, []);

  useEffect(() => {
    setStatusLoader(true);
    setElevatorLoader(true);

    const params = {
      PARENT_ID: userProfile?.["custom:parent_id"],
      TENANT_ID: userProfile?.["custom:tenant_id"],
      FILTER: "daily",
      from: new Date(`${moment().format("YYYY-MM-DD")}T${currentT}`).getTime(),
      to: new Date(
        `${moment().add(1, "days").format("YYYY-MM-DD")}T${currentT}`
      ).getTime(),
    };

    axios
      .post(`${RTMBaseUrl}/elevator-status`, params, {
        headers: { ["x-access-token"]: accessToken },
        ["x-offset"]: -(new Date()).getTimezoneOffset(),
      })
      .then((response) => {
        setelEvatorStatus(response?.data?.data);
        setStatusLoader(false);
      });

    axios
      .post(
        `${RTMBaseUrl}/elevatorUtilization`,
        {
          PARENT_ID: userProfile?.["custom:parent_id"],
          TENANT_ID: userProfile?.["custom:tenant_id"],
        },
        {
          headers: { ["x-access-token"]: accessToken },
        }
      )
      .then((response) => {
        setElevatorUtilization(response?.data?.data);
        setElevatorLoader(false);
      });
  }, []);

  useEffect(() => {
    setUtilizedLoader(true);
    const params = {
      page: pageNo ? pageNo : 1,
      limit: pageSize ? pageSize : 2,
      sortOrder: mostLeastkey,
      sortValue: "FLOOR",
    };

    axios
      .post(
        `${RTMBaseUrl}/utilizationMetrics`,
        params,
        {
          headers: { ["x-access-token"]: accessToken, ["x-offset"]: -(new Date()).getTimezoneOffset() },
        }
      )
      .then((response) => {
        setUtilizationTableDatasource(response.data.data.response);
        setPagiationCount(response?.data?.data?.count);
        setUtilizedLoader(false);
      });
  }, [pageSize, pageNo, mostLeastkey]);

  useEffect(() => {
    setIsLoading(true);
    if (
      elevatorUtilization?.today !== undefined &&
      elevatorUtilization?.yesterday !== undefined
    ) {
      Object?.values(elevatorUtilization?.today)?.map(
        (value, i) =>

          (elevatorUtilizationtabledata[i].today = value)
      );
      Object?.values(elevatorUtilization?.yesterday)?.map(
        (value, i) => (elevatorUtilizationtabledata[i].yesterday = value)
      );
      const newEquipmentUtilizationData = elevatorUtilizationtabledata.filter((_, index) => index !== 0 && index !== 3);
      if (deviceType === "Elevator") {
        setUtilizationData([...elevatorUtilizationtabledata]);
      } else {
        setUtilizationData([...newEquipmentUtilizationData]);
      }

      setIsLoading(false);
    }
  }, [elevatorUtilization]);

  let barChartData = [];
  let PieChartData = [];
  let barChart = ["HIGH", "MEDIUM", "LOW"];
  for (let key in alertBarDashboard) {
    let arrName = barChart.includes(key) ? barChartData : PieChartData;
    arrName.push({
      type: key,
      value: alertBarDashboard[key],
    });
  }
  const handleMostButtonClick = () => {
    setMostLeastkey(-1);
    setPageNo(1)
  };
  const handleLeastButtonClick = () => {
    setMostLeastkey(1);
    setPageNo(1)
  };
  return (
    <>
      <div>
        <Modal
          title="END USER LICENSE TERMS"
          open={UaModalOpen}
          width="auto"
          bodyStyle={{ height: "75vh", padding: "0" }}
          style={{ top: 20 }}
          closable={false}
          footer={[
            <Checkbox style={{ float: "left" }} onClick={toggleAgree} key="iagree">I hereby acknowledge that I have read and agreed to the above terms and conditions </Checkbox>,
            <Button key="cancel" onClick={handleDisagree}>
              Disagree
            </Button>,
            <Button disabled={!UserAccepted} type="primary" key="confirm" onClick={handleAgree} >
              Agree
            </Button>
          ]}
        >
          <Tabs
            animated={true}
            defaultActiveKey="1"
            tabPosition="left"
            items={modalTabs}
          />
        </Modal>
        <DashboardFilter
          setIsLoading={setIsLoading}
          setDashboardMapData={setDashboardMapData}
          setHeaderFilter={setHeaderFilter}
          headerFilter={headerFilter}
          setSelectedDates={setSelectedDates}
          selectedDates={selectedDates}
        />
        <Row style={{ backgroundColor: "#ebeced" }} >
          <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Card className="cardDashboard1">
              <h4 className="headText">All {deviceType}s Today</h4>
              {!statusLoader ? (
                <>
                  <DemoPie chartData={elevatorStatus} called="elevatorStatus" />
                </>
              ) : (
                <Spin
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "140px",
                  }}
                />
              )}
            </Card>
          </Col>
          <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Card className="cardDashboard1">
              <h4 className="headText">{deviceType}s Location Map</h4>
              {dashboardMapData ? (
                <DashboardMap
                  mapdata={dashboardMapData}
                />
              ) : (
                <Spin
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "140px",
                  }}
                />
              )}
            </Card>
          </Col>
          <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Card className="cardDashboard2">
              <h4 className="headText22">Utilization Metrics</h4>
              <Row
                gutter={12}
                style={{ display: "flex", justifyContent: "right" }}
              >
                <Col>
                  <Tooltip title="Least utilization">
                    <UpSquareOutlined onClick={handleLeastButtonClick} className={ mostLeastkey === 1 ? "utilizationButtonStyle" : "utilizationButtonNormalStyle" } />
                  </Tooltip>
                </Col>
                <Col>
                <Tooltip title="Most utilization">
                    <DownSquareOutlined onClick={handleMostButtonClick} className={ mostLeastkey === -1 ? "utilizationButtonStyle" : "utilizationButtonNormalStyle" } />
                  </Tooltip>
                </Col>
              </Row>
              {!utilizedLoader ? (
                <>
                  <div className="table-background">
                    <Table
                      rowClassName={(record, i) =>
                        i % 2 === 0 ? "table-row-light" : "table-row-dark"
                      }
                      style={{ cursor: "pointer" }}
                      onRow={(elevator) => ({
                        onClick: () => {
                          navigate("/real-time-monitoring-perameter", {
                            state: { states: elevator },
                          });
                        },
                      })}
                      className="MainTable utilizedTable"
                      columns={mostUtilizationCol}
                      dataSource={utilizationTableDatasource}
                      pagination={false}
                      loading={isLoading ? true : false}
                    />
                    <Row gutter={[0, 10]}>
                      <Col
                        span={24}
                        style={{ display: "flex", justifyContent: "right" }}
                      >
                        <Pagination
                          defaultCurrent={1}
                          total={paginationCount}
                          onChange={(page, pageSize) => {
                            setPageNo(page);
                            setPageSize(pageSize);
                          }}
                        />
                      </Col>
                      {/* <Col
                        span={24}
                        style={{ display: "flex", justifyContent: "right" }}
                      >
                        <a onClick={() => navigate("/reports-analysis")}>
                          More....
                        </a>
                      </Col> */}
                    </Row>
                  </div>
                </>
              ) : (
                <Spin
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "140px",
                  }}
                />
              )}
            </Card>
          </Col>
          <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Card className="cardDashboard2">
              <h4 className="headText">{deviceType}s Utilization</h4>
              {!elevatorLoader ? (
                <>

                  <div style={{ margin: "10px 0px" }}>
                    {deviceType === "Elevator" &&
                      <BarChartDashboard
                        chartData={[
                          elevatorUtilization?.today?.totalFloors,
                          elevatorUtilization?.yesterday?.totalFloors,
                        ]}
                      />
                    }

                  </div>
                  <Table
                    className="MainTable"
                    columns={elevatorUtilizationtableColumns}
                    dataSource={utilizationData && utilizationData}
                    pagination={false}
                    loading={isLoading ? true : false}
                  />
                </>
              ) : (
                <Spin
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "140px",
                  }}
                />
              )}
            </Card>
          </Col>

          <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Card className="cardDashboard3">
              <EvelatorList name={deviceType} />
            </Card>
          </Col>
          <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Card className="cardDashboard3">
              <h4 className="headText">Recent Alerts</h4>
              <Table
                className="MainTable"
                scroll={{
                  y: 200,
                }}
                columns={alertNotificationcol}
                dataSource={alertDashboard?.RECENT}
                pagination={false}
              />
            </Card>
          </Col>
          <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Card className="cardDashboard2">
              <h4 className="headText">Alerts by Category</h4>
              <DemoPie chartData={PieChartData} called={"alerts"} />
              <h4 className="headText">Alerts by Priority</h4>

              <BarChartDashboard chartData={barChartData} called={"alertBar"} />
            </Card>
          </Col>
          <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Card className="cardDashboard2">
              <h4 className="headText">Alerts Status</h4>
              <Row>
                <Col
                  span={8}
                  style={{ textAlign: "center", fontWeight: "bold" }}
                // key={index}
                >
                  <div className="card-4">{alertDashboard?.OPEN}</div>
                  <ExclamationCircleOutlined /> &nbsp;
                  <span>OPEN</span>
                </Col>
                <Col
                  span={8}
                  style={{ textAlign: "center", fontWeight: "bold" }}
                // key={index}
                >
                  <div className="card-4">{alertDashboard?.IN_PROGRESS}</div>
                  <ClockCircleOutlined /> &nbsp;
                  <span>INPROGRESS</span>
                </Col>
                <Col
                  span={8}
                  style={{ textAlign: "center", fontWeight: "bold" }}
                // key={index}
                >
                  <div className="card-4">{alertDashboard?.CLOSE}</div>
                  <CheckCircleOutlined /> &nbsp;
                  <span>CLOSE</span>
                </Col>
              </Row>
              <Row style={{ marginTop: "10px" }}>
                <ul>
                  {alertDashboard?.RECENT.map((item) => {
                    return (
                      <li key={item.ALERT_ID}>
                        <span style={{ fontWeight: "bold", color: "#00A8B0" }}>
                          {item.DEVICE_NAME}
                        </span>{" "}
                        :{item.DESCRIPTION}
                      </li>
                    );
                  })}
                </ul>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
      {/* )} */}
    </>
  );
};

export default Dashboard;
