/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { PlusOutlined, MinusCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Select,
  Radio,
  Row,
  Col,
  Input,
  InputNumber,
  Popconfirm,
  DatePicker,
  notification,
  Checkbox,
  Tooltip
} from "antd";
import moment from "moment";
import { onboardingBaseUrl, baseUrl, AlertBaseUrl } from "../../common/BaseUrl";
let userObj = {};
const RegisterMaintenanceForm = ({
  maintenanceDataEdit,
  setOpenEditMaintenanceModal,
  loaderFromEdit,
  setLoaderFromEdit,
  setPageLoader,
  setSelectIndex,
  selectIndex,
  selectFilter,
  setSelectFilter,
  setmMaintenanceMOpen,
  setDefaultTabSelection,
  setAllTableLoader,
  allTableLoader,
  adduserUIID,
  setPageLoaderFromEdit,
  setTypeMaintain,
}) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [filterRemainingDevice, setFilterRemainingDevice] = useState([]);
  const userProfile = JSON.parse(window.localStorage.getItem("profile"));
  const deviceType = window.localStorage.getItem("devicetype");
  const electricaraVisible = userProfile?.ONLY_ELECTRICAL_PARAMETER;
  const accessToken = window.localStorage.getItem("accessToken");
  const [slaceData, setSlaceData] = useState();
  const [availableOptions, setAvailableOptions] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [buildingName, setBuildingName] = useState();
  const [userAPICalled, setUserAPICalled] = useState(false);
  const [popOver, setPopOver] = useState(false);
  const [resetOpen, setresetOpen] = useState(false);
  const [storeIDSelected, setStoreIDSelected] = useState();
  const [userEditData, setUserEditData] = useState();
  const [completeFormReset, setCompleteFormReset] = useState();
  const [userCityData, setUserCityData] = useState();
  const [userCountryData, setuserCountryData] = useState();
  const [generationDayEdit, setGenerationDayEdit] = useState();
  const [recurrance, setRecurrance] = useState();
  const [thresholdPeriod, setThresholdPeriod] = useState();
  const [editDeviceDetails, setEditDeviceDetails] = useState();
  const [elevatorUserList, setElevatorUserList] = useState([]);
  const [monthBase, setMonthBase] = useState({
    getStartMonthNo1: "10",
    getStartMonthNo2: "1",
  });
  const [yearBase, setYearBase] = useState({
    noOfYear: "",
    Yearmonthdtae: "",
  });

  const [maintainanceTimePeriod, setMaintainanceTimePeriod] =
    useState("MONTHLY");
  const [userOption, setUseOption] = useState();
  const [operatorData, setOperatorData] = useState();
  const [inChargeData, setInchargeData] = useState();
  const [adminData, setAdminData] = useState();
  const [apiStorId, setApiStorId] = useState();
  const [apiLocation, setApiLocation] = useState();
  const [apiCity, setApiCity] = useState();
  const [apiCountry, setApiCountry] = useState();
  const [apiCalled, setApiCalled] = useState(false);
  const [userAgainEditData, setUserAgainEditData] = useState();
  const WeekDays = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];



  let storeObj = {};
  if (selectIndex?.location) {
    selectIndex?.location &&
      selectFilter?.[selectIndex?.country]?.CITIES?.[
        selectIndex?.city
      ].LOCATION?.[selectIndex?.location]?.STORE?.map((store, storeIndex) => {
        if (storeObj[store.STORE_NAME]) {
          storeObj[store.STORE_NAME].push(store);
        } else {
          storeObj[store.STORE_NAME] = [store];
        }
      });
  }
  useEffect(() => {
    if (buildingName) {
      const OldArrayList = buildingName[0]?.DEVICE_DETAILS;
      setElevatorUserList((prevList) => [...prevList, ...OldArrayList]);
    }
  }, [buildingName]);

  useEffect(() => {
    if (elevatorUserList) {
      setAvailableOptions(elevatorUserList);
    }
  }, [elevatorUserList]);

  useEffect(() => {
    if (maintenanceDataEdit) {
      setUserAPICalled(true);
      setApiStorId(maintenanceDataEdit?.STORE_ID);
      setCompleteFormReset(maintenanceDataEdit);
      if (maintenanceDataEdit?.MAINTENANCE_DEFINITION) {
        setGenerationDayEdit(
          maintenanceDataEdit?.MAINTENANCE_DEFINITION?.GENERATION_DAY
        );
        setRecurrance(maintenanceDataEdit?.MAINTENANCE_DEFINITION?.RECURRANCE);
        setThresholdPeriod(
          maintenanceDataEdit?.MAINTENANCE_DEFINITION?.THRESHOLD_PERIOD
        );
      }
      if (maintenanceDataEdit?.MAINTENANCE_TYPE) {
        setTypeMaintain(maintenanceDataEdit?.MAINTENANCE_TYPE);
      }
      if (maintenanceDataEdit?.MAINTENANCE_DEFINITION?.THRESHOLD_PERIOD) {
        setMaintainanceTimePeriod(
          maintenanceDataEdit?.MAINTENANCE_DEFINITION?.THRESHOLD_PERIOD
        );
      }
      if (maintenanceDataEdit?.RESPONSIBLE_CRITERIA) {
        setUserEditData(maintenanceDataEdit?.RESPONSIBLE_CRITERIA);


      }
      if (maintenanceDataEdit?.RESPONSIBLE_CRITERIA) {
        setUserAgainEditData(maintenanceDataEdit?.RESPONSIBLE_CRITERIA);
      }


      form.setFieldsValue({
        country: maintenanceDataEdit?.COUNTRY,
        city: maintenanceDataEdit?.CITY,
        location: maintenanceDataEdit?.LOCATION,
        building: maintenanceDataEdit?.STORE_NAME,
        user: adduserUIID,
        users: maintenanceDataEdit?.RESPONSIBLE_CRITERIA,
        description: maintenanceDataEdit?.DESCRIPTION,
        maintenanceType: maintenanceDataEdit?.MAINTENANCE_TYPE,
        timeDuration:
          maintenanceDataEdit?.MAINTENANCE_DEFINITION?.THRESHOLD_PERIOD,
        no_of_week: parseInt(
          maintenanceDataEdit?.MAINTENANCE_DEFINITION?.RECURRANCE
        ),
        Working_Days: WeekDays.includes(
          maintenanceDataEdit?.MAINTENANCE_DEFINITION?.GENERATION_DAY
        )
          ? maintenanceDataEdit?.MAINTENANCE_DEFINITION?.GENERATION_DAY
          : "",
        no_of_Day: parseInt(
          maintenanceDataEdit?.MAINTENANCE_DEFINITION?.GENERATION_DAY
        ),
        no_of_month: parseInt(
          maintenanceDataEdit?.MAINTENANCE_DEFINITION?.RECURRANCE
        ),
        date_in_Month: parseInt(
          maintenanceDataEdit?.MAINTENANCE_DEFINITION?.RECURRANCE
        ),
        floorCount:
          maintenanceDataEdit?.MAINTENANCE_DEFINITION?.THRESHOLD_LIMIT,
        // set_trigger:
        //   maintenanceDataEdit?.MAINTENANCE_DEFINITION?.THRESHOLD_PERIOD,
        select_date: maintenanceDataEdit?.MAINTENANCE_DEFINITION?.GENERATION_DAY
          ? moment(
            maintenanceDataEdit?.MAINTENANCE_DEFINITION?.GENERATION_DAY,
            "MM-DD"
          )
          : "",
      });
    }
  }, [maintenanceDataEdit, adduserUIID,]);
  const onFinish = (values) => {
    values?.user?.map((da) => {
      // subUser?.map((j) => {
      //   da.ASSIGNED_TO = j;
      // });
      da.DEVICE_ID = userObj[da.ELEVATOR_NAME];
    });
    values?.users?.map((da, i) => {
      da.EMAIL = values?.users?.[i]?.COMMUNICATION_MEDIUM?.includes("EMAIL");
      da.SMS = values?.users?.[i]?.COMMUNICATION_MEDIUM?.includes("SMS");
    });
    const params = {
      COUNTRY: values?.country,
      CITY: values?.city,
      LOCATION: values?.location,
      STORE_ID: storeIDSelected,
      STORE_NAME: values?.building,
      TENANT_ID: userProfile?.["custom:tenant_id"],
      PARENT_ID: userProfile?.["custom:parent_id"],
      DEVICE_DETAILS: values?.user,
      DESCRIPTION: values?.description,
      CREATED_ON: moment().format("YYYY-MM-DDTHH:mm:ss"),
      MAINTENANCE_TYPE: values?.maintenanceType,
      RESPONSIBLE_CRITERIA: values?.users,
      MAINTENANCE_DEFINITION:
        values?.maintenanceType === 0 && values?.timeDuration === "WEEKLY"
          ? {
            THRESHOLD_PERIOD: values?.timeDuration,
            GENERATION_DAY: values?.Working_Days,
            RECURRANCE: values?.no_of_week?.toString(),
          }
          : values?.maintenanceType === 0 && values?.timeDuration === "MONTHLY"
            ? {
              THRESHOLD_PERIOD: values?.timeDuration,
              GENERATION_DAY: values?.no_of_Day?.toString(),
              RECURRANCE: values?.no_of_month?.toString(),
            }
            : values?.maintenanceType === 0 && values?.timeDuration === "YEARLY"
              ? {
                THRESHOLD_PERIOD: values?.timeDuration,
                GENERATION_DAY: yearBase?.Yearmonthdtae,
                RECURRANCE: values?.date_in_Month?.toString(),
              }
              : values?.maintenanceType === 1 && {
                THRESHOLD_LIMIT: values?.floorCount,
                THRESHOLD_PERIOD: "",
              },
    };
    if (maintenanceDataEdit) {
      const formdata = form.getFieldsValue();
      formdata?.user?.map((da, i) => {
        da.DEVICE_ID =
          maintenanceDataEdit?.DEVICE_DETAILS?.[i]?.DEVICE_ID ||
          userObj[da.ELEVATOR_NAME];
      });
      formdata?.users?.map((da, i) => {
        da.EMAIL = formdata?.users?.[i]?.COMMUNICATION_MEDIUM?.includes("EMAIL");
        da.SMS = formdata?.users?.[i]?.COMMUNICATION_MEDIUM?.includes("SMS");
      });

      const editParams = {
        RESPONSIBLE_CRITERIA: formdata?.users,
        CREATED_ON: maintenanceDataEdit?.CREATED_ON,
        COUNTRY: formdata?.country,
        CITY: formdata?.city,
        LOCATION: formdata?.location,
        STORE_ID: maintenanceDataEdit?.STORE_ID,
        STORE_NAME: formdata?.building,
        TENANT_ID: userProfile?.["custom:tenant_id"],
        PARENT_ID: userProfile?.["custom:parent_id"],
        DEVICE_DETAILS: formdata?.user || adduserUIID,
        DESCRIPTION: formdata?.description,
        MAINTENANCE_TYPE: formdata?.maintenanceType,
        MODIFIED_DUE_DATE:
          (generationDayEdit === formdata?.Working_Days ||
            generationDayEdit === formdata?.no_of_Day ||
            generationDayEdit === formdata?.select_date?.["_i"]) &&
            (recurrance === formdata?.no_of_week ||
              recurrance === formdata?.date_in_Month ||
              recurrance === formdata?.no_of_month) &&
            thresholdPeriod === formdata?.timeDuration
            ? false
            : true,
        MAINTENANCE_DEFINITION:
          formdata?.maintenanceType === 0 && formdata?.timeDuration === "WEEKLY"
            ? {
              THRESHOLD_PERIOD: formdata?.timeDuration,
              RECURRANCE: formdata?.no_of_week,
              GENERATION_DAY: formdata?.Working_Days,
            }
            : formdata?.maintenanceType === 0 &&
              formdata?.timeDuration === "MONTHLY"
              ? {
                THRESHOLD_PERIOD: formdata?.timeDuration,
                GENERATION_DAY: formdata?.no_of_Day,
                RECURRANCE: formdata?.no_of_month,
              }
              : formdata?.maintenanceType === 0 &&
                formdata?.timeDuration === "YEARLY"
                ? {
                  THRESHOLD_PERIOD: formdata?.timeDuration,
                  GENERATION_DAY:
                    yearBase?.Yearmonthdtae || formdata?.select_date?.["_i"],
                  RECURRANCE: formdata?.date_in_Month,
                }
                : values?.maintenanceType === 1 && {
                  THRESHOLD_LIMIT: formdata?.floorCount,
                  THRESHOLD_PERIOD: "",
                },
      };
      axios
        .post(
          `${AlertBaseUrl}/editMaintenanceConfig/${maintenanceDataEdit?.MAINTENANCE_CONFIG_ID}`,
          // `${AlertBaseUrl}/maintenanceConfig`,
          editParams,
          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          form.resetFields();
          setOpenEditMaintenanceModal(false);
          setDefaultTabSelection("3");
          setPageLoaderFromEdit(true);
          setTimeout(() => setPageLoaderFromEdit(false), 2000);
          notification.success({
            description: "Record Edited",
          });
          setLoaderFromEdit(!loaderFromEdit);
        });
    } else {
      axios.post(`${AlertBaseUrl}/maintenanceConfig`, params, {
        headers: { ["x-access-token"]: accessToken },
      }).then((response) => {
        setmMaintenanceMOpen(false);
        form.resetFields();
        setPageLoader(true);
        setDefaultTabSelection("3");
        setAllTableLoader(!allTableLoader);
        setTimeout(() => { setPageLoader(false); }, 1000);
        notification.success({ description: response?.data?.data?.message === "success" ? "Maintenance Succefully Added" : response?.data?.data?.message, });
      }).catch((error) => {
        if (error.status == 401) {
          setmMaintenanceMOpen(false);
          form.resetFields();
          setPageLoader(true);
          setDefaultTabSelection("3");
          setAllTableLoader(!allTableLoader);
          setTimeout(() => { setPageLoader(false); }, 1000);
          notification.error({ description: "Operation unauthorized" });
        }
        console.error("There was an error!", error);
      });
    }
  };
  useEffect(() => {
    const params = {
      PARENT_ID: userProfile?.["custom:parent_id"],
      TENANT_ID: userProfile?.["custom:tenant_id"],
    };
    axios
      .post(`${onboardingBaseUrl}/store-count/data`, params, {
        headers: { ["x-access-token"]: accessToken },
      })
      .then((response) => {
        setSelectFilter(response?.data?.data);
        setSlaceData(response?.data?.data);
        // setIsLoading(false);
      })
      .catch((error) => {
        // setIsLoading(false);
      });
  }, [maintenanceDataEdit]);
  useEffect(() => {
    if (apiStorId) {
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          {
            ROLES: ["OPERATOR"],
            COUNTRY: apiCountry,
            CITY: apiCity,
            STORE_ID: apiStorId?.toString(),
          },
          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setUseOption(response?.data?.data);
        });
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          {
            ROLES: ["EXECUTIVE AREA"],
            COUNTRY: apiCountry,
            CITY: apiCity,
            LOCATION: apiLocation,
          },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setInchargeData(response?.data?.data);
        });
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          { ROLES: ["ADMIN", "EXECUTIVE GLOBAL"] },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setAdminData(response?.data?.data);
        });
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          {
            ROLES: ["STORE INCHARGE", "OPERATOR"],
            COUNTRY: apiCountry,
            CITY: apiCity,
            STORE_ID: apiStorId?.toString(),
          },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setOperatorData(response?.data?.data);
        });
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          {
            ROLES: ["EXECUTIVE COUNTRY"],
            COUNTRY: apiCountry,
          },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setuserCountryData(response?.data?.data);
        });
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          {
            ROLES: ["EXECUTIVE CITY"],
            COUNTRY: apiCountry,
            CITY: apiCity,
          },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setUserCityData(response?.data?.data);
        });
    }
  }, [apiStorId, apiCalled]);
  let userOnborded = [];
  if (userOption) {
    userOption?.map((item) => {
      userOnborded.push({
        label: item?.EMAIL_ID,
        value: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }

  let userOptionData = [];
  if (inChargeData) {
    inChargeData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }
  if (adminData) {
    adminData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }
  if (operatorData) {
    operatorData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }
  if (userCountryData) {
    userCountryData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }
  if (userCityData) {
    userCityData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }

  const handleFormValuesChange = (changedValues) => {
    const formFieldName = Object.keys(changedValues)[0];
    if (formFieldName === "country") {
      // setSelectedCompanyId(changedValues[formFieldName]);
      setUserEditData("");
      setAvailableOptions([])
      setElevatorUserList([])
      form.setFieldsValue({
        city: undefined,
        location: undefined,
        building: undefined,
        users: [],
        user: [{}],
      });
    } else if (formFieldName === "city") {
      setAvailableOptions([])
      setElevatorUserList([])
      form.setFieldsValue({
        location: undefined,
        building: undefined,
        users: [],
        user: [{}],
      });
    } else if (formFieldName === "location") {
      setAvailableOptions([])
      setElevatorUserList([])
      form.setFieldsValue({ building: undefined, users: [], user: [{}] });
    } else if (formFieldName === "building") {
      setAvailableOptions([])
      setElevatorUserList([])
      form.setFieldsValue({ users: [], user: [{}] });
    } else if (formFieldName === "timeDuration") {
      form.setFieldsValue({
        no_of_week: "",
        Working_Days: "",
        date_in_Month: "",
        select_date: "",
        no_of_Day: "",
        no_of_month: "",
      });
    }

    // else if (formFieldName === "ELEVATOR_NAME") {
    //   form.setFieldsValue({ASSIGNED_TO:undefined  });
    // }
  };
  const monthFormat = "MMMM   DD";

  let count = 0;
  useEffect(() => {
    if (userAPICalled === true) {
      let newAllData
      const params = {
        PARENT_ID: userProfile?.["custom:parent_id"],
        TENANT_ID: userProfile?.["custom:tenant_id"],
      };
      axios
        .post(`${onboardingBaseUrl}/store-count/data`, params, {
          headers: { ["x-access-token"]: accessToken },
        })
        .then((response) => {
          setSlaceData(response?.data?.data);
          newAllData = response?.data?.data

        })
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          {
            ROLES: ["OPERATOR"],
            COUNTRY: maintenanceDataEdit?.COUNTRY,
            CITY: maintenanceDataEdit?.CITY,
            STORE_ID: maintenanceDataEdit?.STORE_ID?.toString(),
          },
          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setUseOption(response?.data?.data);
        });
    }
  }, [userAPICalled, maintenanceDataEdit]);
  useEffect(() => {
    let COUNTRY = maintenanceDataEdit?.COUNTRY;
    let CITY = maintenanceDataEdit?.CITY;
    let LOCATION = maintenanceDataEdit?.LOCATION;
    let STORE_NAME = maintenanceDataEdit?.STORE_NAME;
    let tempObj = {};
    if (slaceData) {
      for (let country of slaceData) {
        if (!tempObj[country["COUNTRY"]]) {
          tempObj[country["COUNTRY"]] = {};
          for (let city of country["CITIES"]) {
            if (!tempObj[country["COUNTRY"]][city["CITY"]]) {
              tempObj[country["COUNTRY"]][city["CITY"]] = {};
              for (let location of city["LOCATION"]) {
                if (
                  !tempObj[country["COUNTRY"]][city["CITY"]][
                  location["LOCATION"]
                  ]
                ) {
                  tempObj[country["COUNTRY"]][city["CITY"]][
                    location["LOCATION"]
                  ] = {};
                  for (let store of location["STORE"]) {
                    if (
                      !tempObj[country["COUNTRY"]][city["CITY"]][
                      location["LOCATION"]
                      ][store["STORE_NAME"]]
                    ) {
                      tempObj[country["COUNTRY"]][city["CITY"]][
                        location["LOCATION"]
                      ][store["STORE_NAME"]] = store["DEVICE_DETAILS"];
                    }
                  }
                }
              }
            }
          }
        }
      }
      setEditDeviceDetails(tempObj[COUNTRY]?.[CITY]?.[LOCATION]?.[STORE_NAME]);
    }
  }, [slaceData])
  let editEleField = form.getFieldValue("user")
  useEffect(() => {
    if (editDeviceDetails && editEleField) {
      let onlyDeviceID = editEleField?.map(
        (obj) => obj?.ELEVATOR_NAME
      );
      const filterDevice = editDeviceDetails.filter(
        (item) => !onlyDeviceID?.includes(item.DEVICE_NAME)
      );
      setFilterRemainingDevice(filterDevice);
    }
  }, [editDeviceDetails, editEleField]);

  const addButtonInValid = () => {
    if (userOptionData?.length === 0) {
      return true;
    } else if ((form.getFieldValue(['users']).length > 0 && form.getFieldValue(['users'])?.[form.getFieldValue(['users'])?.length - 1] === undefined)) {
      return true;
    } else if ((form.getFieldValue(['users']).length > 0 && form.getFieldValue(['users'])?.[form.getFieldValue(['users'])?.length - 1] != undefined)) {
      if (Object.keys(form.getFieldValue(['users'])?.[0])?.length === 0) {
        return true;
      }
      return false;
    }
    return false;
  }

  const removeButtonInvalid = () => {
    if ((form.getFieldValue(['users']).length > 0 && form.getFieldValue(['users'])?.[0] === undefined)) {

      return true;
    }
    else if ((form.getFieldValue(['users']).length > 0 && form.getFieldValue(['users'])?.[0] != undefined)) {

      if (Object.keys(form.getFieldValue(['users'])?.[0])?.length === 0) {
        return true;
      }
      return false;
    }
    return false;
    // Object?.keys(form?.getFieldValue(['users'])?.[0]).length===0
  }

  return (
    <div style={{ width: "98%" }}>
      <Form
        name="Maintenance Form"
        onFinish={onFinish}
        onValuesChange={handleFormValuesChange}
        form={form}
        initialValues={{
          maintenanceType: 0,
          no_of_Day: 10,
          no_of_month: 1,
          floorCount: 1,
          timeDuration: "MONTHLY",
          Working_Days: "MON",
        }}
      >
        <Row gutter={[15, 15]}>
          <Col span={12}>
            <div className="formInputStyleLocation">
              <label className="formLabelStyle">Country</label>
              <Form.Item
                name="country"
                rules={[
                  {
                    required: true,
                    message: "Please Select Country!",
                  },
                ]}
              >
                <Select
                  placeholder="Country"
                  disabled={maintenanceDataEdit?.COUNTRY ? true : false}
                  onChange={(value, key) => {
                    setSelectIndex({ ...selectIndex, country: key.key });
                    setApiCountry(selectFilter?.[key.key]?.COUNTRY);
                  }}
                >
                  {selectFilter?.map((country, index) => {
                    return (
                      <Option value={country?.COUNTRY} key={index}>
                        {country?.COUNTRY}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col span={12}>
            <div className="formInputStyleLocation">
              <label className="formLabelStyle">City</label>
              <Form.Item
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Please Select City!",
                  },
                ]}
              >
                <Select
                  onChange={(value, key) => {
                    setSelectIndex({ ...selectIndex, city: key.key });
                    setApiCity(
                      selectFilter?.[selectIndex?.country]?.CITIES?.[key.key]
                        ?.CITY
                    );
                  }}
                  placeholder="City"
                  disabled={
                    form.getFieldValue("country") && !maintenanceDataEdit?.CITY
                      ? false
                      : true
                  }
                  className="ReportModal"
                >
                  {selectFilter?.[selectIndex?.country]?.CITIES?.map(
                    (city, cityIndex) => {
                      return (
                        <Option value={city?.CITY} key={cityIndex}>
                          {city?.CITY}
                        </Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col span={12}>
            <div className="formInputStyleLocation">
              <label className="formLabelStyle">Location</label>
              <Form.Item
                // label="Location"
                name="location"
                rules={[
                  {
                    required: true,
                    message: "Please Select Location!",
                  },
                ]}
              >
                <Select
                  placeholder="Location"
                  className="ReportModal"
                  onChange={(value, key) => {
                    setSelectIndex({ ...selectIndex, location: key.key });
                    setApiLocation(
                      selectFilter?.[selectIndex?.country]?.CITIES?.[
                        selectIndex?.city
                      ].LOCATION?.[key.key]?.LOCATION
                    );
                  }}
                  disabled={
                    form.getFieldValue("city") && !maintenanceDataEdit?.LOCATION
                      ? false
                      : true
                  }
                >
                  {selectIndex?.city &&
                    selectFilter?.[selectIndex?.country]?.CITIES?.[
                      selectIndex?.city
                    ].LOCATION?.map((store, storeIndex) => {
                      return (
                        <Option value={store?.LOCATION} key={storeIndex}>
                          {store?.LOCATION}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col span={12}>
            <div className="formInputStyleLocation">
              <label className="formLabelStyle">Building</label>
              <Form.Item
                name="building"
                rules={[
                  {
                    required: true,
                    message: "Please Select Building!",
                  },
                ]}
              >
                <Select
                  onChange={(value, key) => {
                    setSelectIndex({ ...selectIndex, store: key.key });
                    setBuildingName(storeObj[key?.children]);
                    setStoreIDSelected(
                      selectIndex?.location &&
                      selectFilter?.[selectIndex?.country]?.CITIES?.[
                        selectIndex?.city
                      ].LOCATION?.[selectIndex?.location]?.STORE?.[key.key]
                        ?.STORE_ID
                    );
                    setApiStorId(
                      selectFilter?.[selectIndex?.country]?.CITIES?.[
                        selectIndex?.city
                      ].LOCATION?.[selectIndex?.location]?.STORE?.[key.key]
                        ?.STORE_ID
                    );
                  }}
                  placeholder="Building"
                  className="ReportModal"
                  disabled={
                    form.getFieldValue("location") &&
                      !maintenanceDataEdit?.STORE_ID
                      ? false
                      : true
                  }
                >
                  {selectIndex?.location &&
                    selectFilter?.[selectIndex?.country]?.CITIES?.[
                      selectIndex?.city
                    ].LOCATION?.[selectIndex?.location]?.STORE?.map(
                      (store, storeIndex) => {
                        return (
                          <Option value={store?.STORE_NAME} key={storeIndex}>
                            {store?.STORE_NAME}
                          </Option>
                        );
                      }
                    )}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <>
            <Form.List name="user" initialValue={[{}]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <>
                      <Col span={12}>
                        <div className="formInputStyleLocation">
                          <label className="formLabelStyle">{deviceType}</label>
                          <Form.Item
                            {...restField}
                            name={[name, "ELEVATOR_NAME"]}
                            rules={[
                              {
                                required: true,
                                message: `Select ${deviceType}`,
                              },
                            ]}
                          >
                            {maintenanceDataEdit ? (
                              <Select
                                placeholder={deviceType}
                                className="ReportModal"
                                onChange={(value, key) => {
                                  let deviceId =
                                    filterRemainingDevice[key.key]["DEVICE_ID"];
                                  userObj[value] = deviceId;
                                  filterRemainingDevice?.splice(key.key, 1);

                                  setSelectIndex({
                                    ...selectIndex,
                                    elevator: key.key,
                                  });
                                  if (filterRemainingDevice === 0)
                                    setresetOpen(false);
                                }}
                              >
                                {filterRemainingDevice?.map(
                                  (device, deviceIndex) => {
                                    return (
                                      <Option
                                        value={device?.DEVICE_NAME}
                                        key={deviceIndex}
                                      >
                                        {device?.DEVICE_NAME}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                            ) : (
                              <Select
                                placeholder={deviceType}
                                className="ReportModal"
                                onChange={(value, key) => {
                                  let deviceName =
                                    buildingName[key.key]?.["DEVICE_DETAILS"]?.[
                                    key.key
                                    ]?.["DEVICE_NAME"];
                                  let deviceId =
                                    buildingName[key.key]?.["DEVICE_DETAILS"]?.[
                                    key.key
                                    ]?.["DEVICE_ID"];
                                  userObj[deviceName] = deviceId;
                                  setSelectedUsers((prevSelectedUsers) => {
                                    const updatedSelectedUsers = {
                                      ...prevSelectedUsers,
                                      [name]: value,
                                    };
                                    setAvailableOptions(
                                      elevatorUserList?.filter(
                                        (item) =>
                                          !Object.values(
                                            updatedSelectedUsers
                                          ).includes(item.DEVICE_NAME)
                                      )
                                    );
                                    return updatedSelectedUsers;
                                  });
                                  selectIndex?.store &&
                                    // elevatorUserList?.splice(key.key, 1);
                                    setSelectIndex({
                                      ...selectIndex,
                                      elevator: key.key,
                                    });
                                  if (elevatorUserList === 0)
                                    setresetOpen(false);
                                }}
                                disabled={
                                  selectIndex?.country &&
                                    selectIndex?.city &&
                                    selectIndex?.location
                                    ? false
                                    : true
                                }
                              >
                                {availableOptions?.map((device, storeIndex) => {
                                  return (
                                    <Option
                                      value={device?.DEVICE_NAME}
                                      key={storeIndex}
                                    >
                                      {device?.DEVICE_NAME}
                                    </Option>
                                  );
                                })}
                                {/*  {selectIndex?.store &&
                                  selectFilter?.[
                                    selectIndex?.country
                                  ]?.CITIES?.[selectIndex?.city].LOCATION?.[
                                    selectIndex?.location
                                  ]?.STORE?.[
                                    selectIndex?.store
                                  ]?.DEVICE_DETAILS?.map(
                                    (device, deviceIndex) => {
                                      return (
                                        <Option
                                          value={device?.DEVICE_NAME}
                                          key={deviceIndex}
                                        >
                                          {device?.DEVICE_NAME}
                                        </Option>
                                      );
                                    }
                                  )}*/}
                              </Select>
                            )}
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={10}>
                        <div className="formInputStyleLocation">
                          <label className="formLabelStyle">Asign User</label>
                          <Form.Item
                            // label="User"
                            {...restField}
                            name={[name, "ASSIGNED_TO"]}
                            rules={[
                              {
                                required: true,
                                message: "Select User",
                              },
                            ]}
                          >
                            <Select
                              className="ReportModal"
                              placeholder={"User"}
                              // onChange={(value) => handleUser(value)}
                              options={userOnborded}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={2}>
                        <Button
                          style={{ marginTop: "10px", borderRadius: "5px" }}
                          //  disabled={fields.length === 1}

                          onClick={() => {
                            setAvailableOptions([
                              ...availableOptions,
                              elevatorUserList.find(
                                (item) =>
                                  item.DEVICE_NAME === selectedUsers[name]
                              ),
                            ]);
                            remove(name);
                          }}
                        >
                          <MinusCircleOutlined />
                        </Button>
                      </Col>
                    </>
                  ))}
                  <Form.Item>
                    <Button
                      disabled={
                        (availableOptions && availableOptions.length === 0 && filterRemainingDevice.length === 0)
                          // && !resetOpen
                          ? true
                          : false
                      }
                      type="dashed"
                      onClick={() => {
                        add();
                        // if (maintenanceDataEdit) {
                        //   setUserAPICalled(true);
                        // }
                      }}
                      block
                      icon={<PlusOutlined />}
                      style={{ marginLeft: "10px" }}
                    >
                      Add {deviceType} and User
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </>
          <Col span={24}>
            <Row className="rowHeadingStyle11">User Notify</Row>
            <Form.List name="users" initialValue={[]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <Row key={index} gutter={[12, 12]}>
                      <Col xs={12} sm={12} lg={8}>
                        <div className="formInputStyle">
                          <label className="formLabelStyle">User</label>
                          <Form.Item
                            {...restField}
                            name={[name, "USERID"]}
                            rules={[ { required: true, message: "Select USERID", }, ]}
                          >
                            <Select
                              placeholder="User"
                              className="ReportModal"
                              onChange={(value, key) => {
                                let data = [...form.getFieldValue("users")];
                                data[data.length - 1]["PHONE_NUMBER"] =
                                  userOptionData?.[key.key]?.mobile;
                                form.setFieldsValue({ users: data });
                                userOptionData.splice(key.key, 1);
                              }}
                            //   disabled={
                            //     selectIndex?.country &&
                            //     selectIndex?.city &&
                            //     selectIndex?.location
                            //       ? false
                            //       : true
                            //   }
                            >
                              {userOptionData?.map((device, storeIndex) => {
                                return (
                                  <Option
                                    value={device?.label}
                                    key={storeIndex}
                                  >
                                    {device?.label}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} lg={8}>
                        <div className="formInputStyle">
                          <label className="formLabelStyle">Mobile No.</label>
                          <Form.Item
                            {...restField}
                            name={[name, "PHONE_NUMBER"]}
                            rules={[ { required: true, message: "Mobile no.", }, ]}
                          >
                            <Input
                              disabled
                              className="formInput"
                              placeholder={"Mobile no."}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} lg={6}>
                        <div className="formInputStyle">
                          <label className="formLabelStyle">
                            Communication Medium
                          </label>
                          <div style={{ display: "flex", height: "30px" }}>
                            <Form.Item
                              {...restField}
                              name={[name, "COMMUNICATION_MEDIUM"]}
                              rules={[ { required: true, message: "Select the Communication Medium", }, ]}
                            >
                              <Checkbox.Group>
                                <Checkbox value="EMAIL">Email</Checkbox>
                                <Checkbox value="SMS">SMS</Checkbox>
                              </Checkbox.Group>
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                      <Col span={2}>
                        <Button
                          // disabled={removeButtonInvalid()}
                          //  disabled={form.getFieldValue(['users'])?.length<=1}
                          style={{ marginTop: "30px" }} onClick={() => {
                            if (form?.getFieldValue(['users']).length === 1) {
                              form.setFieldsValue({ ["users"]: [] });
                              return;
                            }
                            if (userAgainEditData) {
                              userAgainEditData.splice(name, 1);
                              setApiCalled(!apiCalled);
                            }
                            remove(name);
                          }}>
                          <MinusCircleOutlined

                          />
                        </Button>
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button
                      disabled={addButtonInValid()}
                      // disabled={userOptionData?.length === 0||(form.getFieldValue(['users']).length>0&&form.getFieldValue(['users'])?.[form.getFieldValue(['users'])?.length-1]===undefined)}
                      // disabled={userOptionData?.length === 0 ||!form.getFieldValue(['users'])?.[form.getFieldValue(['users'])?.length-1]||Object.keys(form.getFieldValue(['users'])?.[0]).length === 0}
                      type="primary"
                      onClick={() => {
                        //   console.log("form.getFieldValue",form.getFieldValue(['users']))
                        //  let tempUsers=form.getFieldValue(['users']);
                        //  let tempUsers2=form.getFieldValue(['users'])?.filter(item=>item!==undefined);
                        //  if(tempUsers?.length!==tempUsers2.length||Object.keys(tempUsers[0]).length === 0)
                        //    {
                        //      return;
                        //    }
                        add();
                        let tempObj = {};
                        userAgainEditData?.map(
                          (item) => (tempObj[item["USERID"]] = 1)
                        );

                        userOptionData = userOptionData?.filter((item) => {
                          if (!tempObj[item["label"]]) {
                            return item;
                          }
                        });
                      }}
                      block
                      icon={<PlusOutlined />}
                      style={{ width: "120px", marginTop: "30px" }}
                    >
                      Add User
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
          <Col span={24}>
            <div className="formInputStyleLocation">
              <label className="formLabelStyle">Description</label>
              <Tooltip placement="right" title="Please do not enter any personal information.">&nbsp;<InfoCircleOutlined /></Tooltip>
              <Form.Item name="description">
                <Input placeholder="Description" className="formInput" />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row className="rowHeadingStyle11" style={{ marginTop: "15px" }}>
          Maintainance Type
        </Row>
        <Row
          gutter={[24, 15]}
          style={{
            backgroundColor: "#f5f5f5",
            padding: "0px 20px",
            marginRight: "5px",
          }}
        >
          <Col span={24}>
            <Form.Item name="maintenanceType">
              {electricaraVisible === true ? (
                <Radio.Group
                  onChange={(e) => setTypeMaintain(e.target.value)}
                  disabled={completeFormReset ? true : false}
                >
                  <Radio value={0}>Time</Radio>
                </Radio.Group>
              ) : (
                <Radio.Group
                  onChange={(e) => setTypeMaintain(e.target.value)}
                  disabled={completeFormReset ? true : false}
                >
                  <Radio value={0}>Time</Radio>
                  <Radio value={1}>Floor</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            {form.getFieldValue("maintenanceType") === 1 ? (
              <Row gutter={24}>
                {/* <Col span={12}>
                    <Form.Item
                      label="Set the maintainance trigger"
                      name="set_trigger"
                      rules={[
                        {
                          required: true,
                          message: "set the alert trigger",
                        },
                      ]}
                    >
                      <Radio.Group className="Trigger">
                        {alertTripSpan?.map((data) => {
                          return (
                            <Radio.Button
                              value={data.value}
                              style={{
                                border: "1.5px solid #005691",
                                width: "130px",
                                textAlign: "center",
                                color: "black",
                                fontWeight: "600",
                              }}
                              // defaultChecked={
                              //   floorMaintainanceData?.tringerFloorDuration
                              // }
                              // onChange={(e) =>                            //   setFloorMaintainanceData({
                              //     ...floorMaintainanceData,
                              //     tringerFloorDuration: e.target.value,
                              //   })
                              // }
                            >
                              {data.label}
                            </Radio.Button>
                          );
                        })}
                      </Radio.Group>
                    </Form.Item>
                  </Col> */}
                <Col span={12}>
                  <Form.Item name="floorCount" label="No. of Floors">
                    <InputNumber style={{ width: "100%" }} min={1} required />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col span={4}>
                  <Form.Item
                    name="timeDuration"
                    rules={[
                      {
                        required: true,
                        message: "select Maintainance range",
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={(e) => {
                        setMaintainanceTimePeriod(e.target.value);
                      }}
                      value={maintainanceTimePeriod}
                    >
                      <Radio value="WEEKLY">Weekly</Radio>
                      <Radio value="MONTHLY">Monthly</Radio>
                      <Radio value="YEARLY">Yearly</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={20}>
                  {maintainanceTimePeriod === "WEEKLY" ? (
                    <>
                      <Row>
                        Recur every &nbsp;&nbsp;
                        <Form.Item
                          name="no_of_week"
                          rules={[
                            {
                              required: true,
                              message: "Input the Week",
                            },
                          ]}
                        >
                          <Input required />
                        </Form.Item>
                        &nbsp; week(s) on
                      </Row>
                      <Form.Item
                        name="Working_Days"
                        rules={[
                          {
                            required: true,
                            message: "select day",
                          },
                        ]}
                      >
                        <Radio.Group required>
                          <Radio value="MON">Monday</Radio>
                          <Radio value="TUE">Tuesday</Radio>
                          <Radio value="WED">Wednesday</Radio>
                          <Radio value="THU">Thursday</Radio>
                          <Radio value="FRI">Friday</Radio>
                          <Radio value="SAT">Saturday</Radio>
                          <Radio value="SUN">Sunday</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </>
                  ) : maintainanceTimePeriod === "YEARLY" ? (
                    <>
                      <Row>
                        Recur every &nbsp;&nbsp;
                        <Form.Item
                          name="date_in_Month"
                          rules={[
                            {
                              required: true,
                              message: "Input month",
                            },
                          ]}
                        >
                          <InputNumber
                            min={1}
                            onChange={(e) =>
                              setYearBase({ ...yearBase, noOfYear: e })
                            }
                            required
                            defaultValue={yearBase?.noOfYear}
                          />
                        </Form.Item>
                        &nbsp; year(s) on.
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="formInputStyle">
                            <label className="formLabelStyle">
                              Select Date
                            </label>
                            <Form.Item
                              name="select_date"
                              rules={[
                                {
                                  required: true,
                                  message: "Select the date",
                                },
                              ]}
                            >
                              <DatePicker
                                onChange={(e, timeString) => {
                                  setYearBase({
                                    ...yearBase,
                                    Yearmonthdtae: moment(
                                      timeString,
                                      "MMMM   DD"
                                    ).format("MM-DD"),
                                  });
                                }}
                                // defaultValue={moment()}
                                format={monthFormat}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <Row>
                        Days &nbsp;
                        <Popconfirm
                          open={popOver}
                          onConfirm={() => {
                            setPopOver(false);
                          }}
                          onCancel={() => {
                            setPopOver(false);
                          }}
                          title={`Some months have fewer than ${monthBase?.getStartMonthNo1} days. For these months, the occurrence will fall on the last day of the month`}
                          // trigger="hover"
                          disabled={
                            monthBase?.getStartMonthNo1 === 29
                              ? false
                              : monthBase?.getStartMonthNo1 === 30
                                ? false
                                : monthBase?.getStartMonthNo1 === 31
                                  ? false
                                  : true
                          }
                        >
                          <Form.Item
                            name="no_of_Day"
                            rules={[
                              {
                                required: true,
                                message: "Input no. of Day",
                              },
                            ]}
                          >
                            <InputNumber
                              min={1}
                              max={31}
                              onChange={(e) => {
                                setMonthBase({
                                  ...monthBase,
                                  getStartMonthNo1: e,
                                });
                                {
                                  e < 29 ? setPopOver(false) : setPopOver(true);
                                }
                              }}
                              required
                            // defaultValue={monthBase?.getStartMonthNo1}
                            />
                          </Form.Item>
                        </Popconfirm>
                        &nbsp; of every &nbsp;&nbsp;
                        <Form.Item
                          name="no_of_month"
                          rules={[
                            {
                              required: true,
                              message: "Input no. of month",
                            },
                          ]}
                        >
                          <InputNumber
                            min={1}
                            max={12}
                            onChange={(e) =>
                              setMonthBase({
                                ...monthBase,
                                getEndMonthNo2: e,
                              })
                            }
                            required
                          // defaultValue={monthBase?.getStartMonthNo2}
                          />
                        </Form.Item>
                        &nbsp; month(s).
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        <Form.Item>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>

          {/*  <Button
            htmlType="button"
            onClick={() => {
              form.resetFields();
              setCompleteFormReset("");
              setresetOpen(true);
            }}
          >
            Reset
          </Button>*/}
        </Form.Item>
      </Form>
    </div>
  );
};
export default RegisterMaintenanceForm;