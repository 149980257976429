let dev = "https://u7589ih429.execute-api.ap-south-1.amazonaws.com/dev";
let prod = "https://vdnmu943ag.execute-api.ap-south-1.amazonaws.com/prod";
let production = true;

export const baseUrl = production ? prod + "/user/user/v2/prod" : dev + "/user/user/v2/prod";
export const onboardingBaseUrl = production ? prod + "/onboarding/customer/v2/prod" : dev + "/onboarding/customer/v2/prod";
export const RTMBaseUrl = production ? prod + "/energy-data/energy-data/v2/prod" : dev + "/energy-data/energy-data/v2/prod";
export const AlertBaseUrl = production ? prod + "/alert/alert/v2/prod" : dev + "/alert/alert/v2/prod";

// export const baseUrl = "http://localhost:8083/user/v2/prod";
// export const onboardingBaseUrl = "http://localhost:8081/customer/v2/prod";
// export const RTMBaseUrl = "http://localhost:8084/energy-data/v2/prod";
// export const AlertBaseUrl = "http://localhost:8087/alert/v2/prod";