/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import "antd/dist/antd.css";
import "./Styles.css";
import { baseUrl } from "../../common/BaseUrl";
import axios from "axios";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Row,
  Select,
  Input,
  InputNumber,
} from "antd";
import { Priority, timePeriodOptions } from "./FormData";
import { useEffect } from "react";
import moment from "moment";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const LoadParameterTab = ({
  getAlertList,
  setSelectTab,
  selectFilter,
  setSelectIndex,
  selectIndex,
  setParams,
  editRowData,
  setButtonSave,
  setEventModal,
}) => {
  const accessToken = window.localStorage.getItem("accessToken");
  const deviceType = window.localStorage.getItem("devicetype");
  const { Option } = Select;
  const [form] = Form.useForm();
  const [userLength, setUserlength] = useState(false);
  const userProfile = JSON.parse(window.localStorage.getItem("profile"));
  const [userCityData, setUserCityData] = useState();
  const [userCountryData, setuserCountryData] = useState();
  const [apiCalled, setApiCalled] = useState(false);
  const [adminData, setAdminData] = useState();
  const [operatorData, setOperatorData] = useState();
  const [apiStorId, setApiStorId] = useState();
  const [apiLocation, setApiLocation] = useState();
  const [apiCity, setApiCity] = useState();
  const [apiCountry, setApiCountry] = useState();
  const [inChargeData, setInchargeData] = useState();
  const [loadElevatorsDeviceID, setLoadElevatorsDeviceID] = useState();
  const [userAgainEditData, setUserAgainEditData] = useState();
  const [criteria, setCriteria] = useState();

  const addButtonInValid = () => {
    if (userOptionData?.length === 0) {
      return true;
    }
    else if ((form.getFieldValue(['users']).length > 0 && form.getFieldValue(['users'])?.[form.getFieldValue(['users'])?.length - 1] === undefined)) {

      return true;
    }
    else if ((form.getFieldValue(['users']).length > 0 && form.getFieldValue(['users'])?.[form.getFieldValue(['users'])?.length - 1] != undefined)) {

      if (Object.keys(form.getFieldValue(['users'])?.[0])?.length === 0) {
        return true;
      }
      return false;
    }
    return false;

  }

  const removeButtonInvalid = () => {
    if ((form.getFieldValue(['users']).length > 0 && form.getFieldValue(['users'])?.[0] === undefined)) {

      return true;
    }
    else if ((form.getFieldValue(['users']).length > 0 && form.getFieldValue(['users'])?.[0] != undefined)) {

      if (Object.keys(form.getFieldValue(['users'])?.[0])?.length === 0) {
        return true;
      }
      return false;
    }
    return false;
    // Object?.keys(form?.getFieldValue(['users'])?.[0]).length===0
  }

  useEffect(() => {
    if (editRowData) {
      setApiStorId(editRowData?.STORE_ID);
      setApiLocation(editRowData?.LOCATION);
      setApiCity(editRowData?.CITY);
      setApiCountry(editRowData?.COUNTRY);

      if (editRowData?.RESPONSIBLE_CRITERIA) {
        setUserAgainEditData(editRowData?.RESPONSIBLE_CRITERIA);
      }
      form.setFieldsValue({
        OCCURANCE: editRowData?.OCCURANCE,
        prognosis_alert: editRowData?.IS_PROGNOSIS_ALERT_REQUIRED,
        country: editRowData?.COUNTRY,
        city: editRowData?.CITY,
        location: editRowData?.LOCATION,
        elevator: editRowData?.DEVICE_NAME,
        building: editRowData?.STORE_NAME,
        criteria: editRowData?.CRITERIA === 5 && "1",
        target: editRowData?.TARGET,
        users: editRowData?.RESPONSIBLE_CRITERIA,
        energyTarget: editRowData?.ENERGY_TARGET,
        for: editRowData?.THRESHOLD_PERIOD,
        priority: editRowData?.PRIORITY,
        startDate: moment(editRowData?.TIME_PERIOD_FROM, "YYYY/MM/DD"),
        endDate: moment(editRowData?.TIME_PERIOD_TO, "YYYY/MM/DD"),
      });
    }
  }, [editRowData]);
  const onFinish = (values) => {
    values?.users?.map((da, i) => {
      da.EMAIL = values?.users?.[i]?.COMMUNICATION_MEDIUM?.includes("EMAIL");
      da.SMS = values?.users?.[i]?.COMMUNICATION_MEDIUM?.includes("SMS");
    });
    let paramsValue = {
      OCCURANCE: 0,
      IS_PROGNOSIS_ALERT_REQUIRED: values?.prognosis_alert ? true : false,
      TENANT_ID: userProfile?.["custom:tenant_id"],
      PARAMETER: 1,
      STATUS: "SUBMITTED",
      EVENT_TYPE: 0,
      CREATED_ON: moment().format("YYYY-MM-DDTHH:MM:SS"),
      CRITERIA: 5,
      COUNTRY: values?.country,
      CITY: values?.city,
      LOCATION: values?.location,
      STORE_ID:
        selectIndex?.location &&
        selectFilter?.[selectIndex?.country]?.CITIES?.[selectIndex?.city]
          ?.LOCATION?.[selectIndex?.location]?.STORE?.[selectIndex?.store]
          ?.STORE_ID,
      STORE_NAME: values?.building,
      DEVICE_NAME: values?.elevator,
      DEVICE_ID: loadElevatorsDeviceID,
      RESPONSIBLE_CRITERIA: values?.users,
      ENERGY_LIMIT: 0,
      THRESHOLD_PERIOD: values?.for,
      CATEGORY: "",
      // TIME_PERIOD_FROM: `${startDate}T18:30:00`,
      // TIME_PERIOD_TO: `${endDate}T18:30:00`,
      TARGET: values?.target,
      DESCRIPTION: "Alert from Load Side",
      PRIORITY: values?.priority,
      ENERGY_TARGET: values?.energyTarget,
    };

    if (editRowData) {
      const formdata = form.getFieldsValue();
      formdata?.users?.map((da, i) => {
        da.EMAIL = formdata?.users?.[i]?.COMMUNICATION_MEDIUM?.includes("EMAIL");
        da.SMS = formdata?.users?.[i]?.COMMUNICATION_MEDIUM?.includes("SMS");
      });
      paramsValue.LAST_ALERT_TIME = editRowData?.LAST_ALERT_TIME ? editRowData?.LAST_ALERT_TIME : undefined
      paramsValue.STORE_ID = editRowData?.STORE_ID;
      paramsValue.TIME_PERIOD_FROM = formdata?.startDate?.["_i"];
      paramsValue.TIME_PERIOD_TO = formdata?.endDate?.["_i"];
      paramsValue.EVENT_TYPE = 0;
      paramsValue.PARAMETER = 1;
      paramsValue.DESCRIPTION = "Alert from Load Side";
      paramsValue.CREATED_ON = editRowData?.CREATED_ON;
      paramsValue.DEVICE_ID = editRowData?.DEVICE_ID;
      paramsValue.CRITERIA = 5;
      paramsValue.RESPONSIBLE_CRITERIA = formdata?.users;
      paramsValue.PRIORITY = formdata?.priority;
      paramsValue.TIME_DURATION =
        formdata?.for === "hourly"
          ? 1
          : formdata?.for === "daily"
            ? 2
            : formdata?.for === "weekly"
              ? 3
              : formdata?.for === "monthly"
                ? 4
                : formdata?.for === "quaterly"
                  ? 5
                  : formdata?.for === "halfYearly" && 6;
      paramsValue.ENERGY_TARGET = formdata?.energyTarget;
      paramsValue.TARGET = formdata?.target;
      paramsValue.RESPONSIBLE_CRITERIA = formdata?.users;
    }
    setParams({ ...paramsValue });
    setSelectTab(true);
    setButtonSave(true);
  };

  const handleFormValuesChange = (changedValues) => {
    const formFieldName = Object.keys(changedValues)[0];
    if (formFieldName === "country") {
      form.setFieldsValue({
        city: undefined,
        location: undefined,
        building: undefined,
        elevator: undefined,
        users: [],
      });
    } else if (formFieldName === "city") {
      form.setFieldsValue({
        location: undefined,
        building: undefined,
        elevator: undefined,
        users: [],
      });
    } else if (formFieldName === "location") {
      form.setFieldsValue({
        building: undefined,
        elevator: undefined,
        users: [],
      });
    } else if (formFieldName === "building") {
      form.setFieldsValue({
        elevator: undefined,
        users: [],
      });
    }
  };

  useEffect(() => {
    if (apiStorId) {
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          {
            ROLES: ["EXECUTIVE AREA"],
            COUNTRY: apiCountry,
            CITY: apiCity,
            LOCATION: apiLocation,
          },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setInchargeData(response?.data?.data);
        });
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          { ROLES: ["ADMIN", "EXECUTIVE GLOBAL"] },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setAdminData(response?.data?.data);
        });
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          {
            ROLES: ["STORE INCHARGE", "OPERATOR"],
            COUNTRY: apiCountry,
            CITY: apiCity,
            STORE_ID: apiStorId?.toString(),
          },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setOperatorData(response?.data?.data);
        });
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          {
            ROLES: ["EXECUTIVE COUNTRY"],
            COUNTRY: apiCountry,
          },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setuserCountryData(response?.data?.data);
        });
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          {
            ROLES: ["EXECUTIVE CITY"],
            COUNTRY: apiCountry,
            CITY: apiCity,
          },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setUserCityData(response?.data?.data);
        });
    }
  }, [apiStorId, apiCalled]);

  let userOptionData = [];
  if (inChargeData) {
    inChargeData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }
  if (adminData) {
    adminData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }
  if (operatorData) {
    operatorData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }
  if (userCountryData) {
    userCountryData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }
  if (userCityData) {
    userCityData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }

  useEffect(() => {
    if (form.getFieldValue("users")?.length === 0) {
      setApiCalled(!apiCalled);
    }
  }, [userLength]);

  return (
    <Form
      form={form}
      onValuesChange={handleFormValuesChange}
      onFinish={onFinish}
      style={{ padding: "0px 15px 0px 0px" }}
    >
      <Row className="rowHeadingStyle">
        Type Defination
      </Row>
      {
        criteria == '1'
          ?

          <Row gutter={15}>
            <Col span={15}>
              <Form.Item
                name="prognosis_alert"
                initialValue={true}
                valuePropName="checked">
                <Checkbox >*prompt a special message after every 5th consecutive alert within 24 hours.</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          : <></>
      }
      <Row gutter={15}>
        <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">Country</label>
            <Form.Item
              name="country"
              rules={[
                {
                  required: true,
                  message: "Please Select Country!",
                },
              ]}
            >
              <Select
                placeholder="Country"
                disabled={editRowData?.COUNTRY && true}
                onChange={(value, key) => {
                  setSelectIndex({ ...selectIndex, country: key.key });
                  setApiCountry(selectFilter?.[key.key]?.COUNTRY);
                }}
              >
                {selectFilter?.map((country, index) => {
                  return (
                    <Option value={country?.COUNTRY} key={index}>
                      {country?.COUNTRY}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">City</label>
            <Form.Item
              name="city"
              rules={[
                {
                  required: true,
                  message: "Please Select City!",
                },
              ]}
            >
              <Select
                onChange={(value, key) => {
                  setSelectIndex({ ...selectIndex, city: key.key });
                  setApiCity(
                    selectFilter?.[selectIndex?.country]?.CITIES?.[key.key]
                      ?.CITY
                  );
                }}
                placeholder="Cities"
                disabled={selectIndex?.country ? false : true}
                className="ReportModal"
              >
                {selectFilter?.[selectIndex?.country]?.CITIES?.map(
                  (city, cityIndex) => {
                    return (
                      <Option value={city?.CITY} key={cityIndex}>
                        {city?.CITY}
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">Location</label>
            <Form.Item
              name="location"
              rules={[
                {
                  required: true,
                  message: "Please Select Location!",
                },
              ]}
            >
              <Select
                placeholder="Location"
                onChange={(value, key) => {
                  setSelectIndex({ ...selectIndex, location: key.key });
                  setApiLocation(
                    selectFilter?.[selectIndex?.country]?.CITIES?.[
                      selectIndex?.city
                    ].LOCATION?.[key.key]?.LOCATION
                  );
                }}
                disabled={
                  selectIndex?.country && selectIndex?.city ? false : true
                }
              >
                {selectIndex?.city &&
                  selectFilter?.[selectIndex?.country]?.CITIES?.[
                    selectIndex?.city
                  ].LOCATION?.map((store, storeIndex) => {
                    return (
                      <Option value={store?.LOCATION} key={storeIndex}>
                        {store?.LOCATION}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">Building</label>
            <Form.Item
              name="building"

              rules={[
                {
                  required: true,
                  message: "Please Select Building!",
                },
              ]}
            >
              <Select
                onChange={(value, key) => {
                  setApiStorId(
                    selectFilter?.[selectIndex?.country]?.CITIES?.[
                      selectIndex?.city
                    ].LOCATION?.[selectIndex?.location]?.STORE?.[key.key]
                      ?.STORE_ID
                  );
                  setSelectIndex({ ...selectIndex, store: key.key });
                }}
                placeholder="Building"
                className="ReportModal"
                disabled={selectIndex?.location ? false : true}
              >
                {selectIndex?.location &&
                  selectFilter?.[selectIndex?.country]?.CITIES?.[
                    selectIndex?.city
                  ].LOCATION?.[selectIndex?.location]?.STORE?.map(
                    (store, storeIndex) => {
                      return (
                        <Option value={store?.STORE_NAME} key={storeIndex}>
                          {store?.STORE_NAME}
                        </Option>
                      );
                    }
                  )}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">{deviceType}</label>
            <Form.Item
              name="elevator"
              rules={[
                {
                  required: true,
                  message: "Please Select Elevator!",
                },
              ]}
            >
              <Select
                placeholder={deviceType}
                onChange={(value, key) => {
                  setSelectIndex({
                    ...selectIndex,
                    elevator: key.key,
                  });
                  setLoadElevatorsDeviceID(
                    selectIndex?.store &&
                    selectFilter?.[selectIndex?.country]?.CITIES?.[
                      selectIndex?.city
                    ].LOCATION?.[selectIndex?.location]?.STORE?.[
                      selectIndex?.store
                    ]?.DEVICE_DETAILS?.[key.key]?.DEVICE_ID
                  );
                }}
                disabled={
                  selectIndex?.country &&
                    selectIndex?.city &&
                    selectIndex?.location &&
                    selectIndex?.store
                    ? false
                    : true
                }
              >
                {selectIndex?.store &&
                  selectFilter?.[selectIndex?.country]?.CITIES?.[
                    selectIndex?.city
                  ].LOCATION?.[selectIndex?.location]?.STORE?.[
                    selectIndex?.store
                  ]?.DEVICE_DETAILS?.map((device, deviceIndex) => {
                    return (
                      <Option value={device?.DEVICE_NAME} key={deviceIndex}>
                        {device?.DEVICE_NAME}
                      </Option>
                    );
                  })}
                {/* {elevators?.map((device, storeIndex) => {
                  return (
                    <Option value={device?.DEVICE_NAME} key={storeIndex}>
                      {device?.DEVICE_NAME}
                    </Option>
                  );
                })} */}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">Alert Type</label>
            <Form.Item name="criteria">
              <Select
                placeholder="Alert Type"
                disabled={editRowData?.CRITERIA && true}
                onChange={(value) => setCriteria(value)}
                allowClear>
                <Option value="1">TARGET BREACH</Option>
              </Select>
            </Form.Item>
          </div>
        </Col>

        <Col
          span={24}
          className="rowHeadingStyle11"
          style={{ marginTop: "15px", paddingLeft: "10px" }}
        >
          Set Limit and Period
        </Col>

        <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">Energy Target</label>
            <Form.Item
              name="energyTarget"
              rules={[
                {
                  required: true,
                  message: `Please Select Energy Target`,
                },
              ]}
            >
              <Select>
                <Option value="energy" key="energy">
                  Energy Target Limit
                </Option>
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">For</label>
            <Form.Item
              name="for"
              rules={[
                {
                  required: true,
                  message: `Please Select For`,
                },
              ]}
            >
              <Select>
                {timePeriodOptions?.map((time, index) => {
                  return (
                    <Option value={time?.value} key={index}>
                      {time?.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">Priority</label>

            <Form.Item
              name="priority"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Priority"
                style={{
                  width: "100%",
                }}
                value={Priority.value}
                options={Priority}
              />
            </Form.Item>
          </div>
        </Col>
        <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">Target(kWh)</label>
            <Form.Item
              name="target"
              rules={[
                {
                  required: true,
                  message: `Please Select Target`,
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
          </div>
        </Col>

        {/* <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">Start Date</label>

            <Form.Item
              name="startDate"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker
                onChange={(key, time) => {
                  setStartDate(time);
                }}
              />
            </Form.Item>
          </div>
        </Col>
        <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">End Date</label>

            <Form.Item
              name="endDate"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker
                onChange={(key, time) => {
                  setEndDate(time);
                }}
              />
            </Form.Item>
          </div>
        </Col> */}
      </Row>
      {/* <Row gutter={15}>
        <Col span={6}>Communication Medium</Col>
        <Col span={6}>
          <div className="formInputStyleAlert">
            <Form.Item name="email">
              <Checkbox.Group>
                <Checkbox value={1}>Email</Checkbox>
                <Checkbox value={2}>SMS</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={6}>Define Escalation Level</Col>
        <Col span={8}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">Level 1</label>

            <Form.Item
              name="level1"
              rules={[
                {
                  required: true,
                  message: "Select the level",
                },
              ]}
            >
              <Select
                required
                style={{
                  width: "100%",
                }}
                value={userOptionData.value}
                options={userOptionData}
              />
            </Form.Item>
          </div>
        </Col>
        <Col span={8}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">No. of Days</label>

            <Form.Item name="level1Days">
              <Input type="number" min="1" max="30" />
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={6}></Col>
        <Col span={8}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">Level 2</label>

            <Form.Item name="level2">
              <Select
                required
                style={{
                  width: "100%",
                }}
                value={userOptionData.value}
                options={userOptionData}
              />
            </Form.Item>
          </div>
        </Col>
        <Col span={8}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">No. of Days</label>

            <Form.Item name="level2Days">
              <Input type="number" min="1" max="30" />
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={6}></Col>
        <Col span={8}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">Level 3</label>

            <Form.Item name="level3">
              <Select
                required
                style={{
                  width: "100%",
                }}
                value={userOptionData.value}
                options={userOptionData}
              />
            </Form.Item>
          </div>
        </Col>
      </Row> */}

      <Row className="rowHeadingStyle11" style={{ marginTop: "15px" }}>
        User Notify
      </Row>
      <Form.List name="users" initialValue={[]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Row key={index} gutter={[12, 12]}>
                <Col span={8}>
                  <div className="formInputStyle">
                    <label className="formLabelStyle">User</label>
                    <Form.Item
                      {...restField}
                      name={[name, "USERID"]}
                      rules={[ { required: true, message: "Select USERID", }, ]}
                    >
                      <Select
                        placeholder="User"
                        className="ReportModal"
                        onChange={(value, key) => {
                          let data = [...form.getFieldValue("users")];
                          data[data.length - 1]["PHONE_NUMBER"] =
                            userOptionData?.[key.key]?.mobile;
                          form.setFieldsValue({ users: data });
                          // count++;
                          userOptionData.splice(key.key, 1);
                        }}
                      // disabled={
                      //   selectIndex?.country &&
                      //   selectIndex?.city &&
                      //   selectIndex?.location
                      //     ? false
                      //     : true
                      // }
                      >
                        {userOptionData?.map((device, storeIndex) => {
                          return (
                            <Option value={device?.label} key={storeIndex}>
                              {device?.label}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col span={7}>
                  <div className="formInputStyle">
                    <label className="formLabelStyle">Mobile No.</label>
                    <Form.Item
                      {...restField}
                      name={[name, "PHONE_NUMBER"]}
                      rules={[ { required: true, message: "Mobile no.", }, ]}
                    >
                      <Input
                        disabled
                        className="formInput"
                        placeholder={"Mobile no."}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={7}>
                  <div className="formInputStyle">
                    <label className="formLabelStyle">
                      Communication Medium
                    </label>
                    <div style={{ display: "flex", height: "30px" }}>
                      <Form.Item
                        {...restField}
                        name={[name, "COMMUNICATION_MEDIUM"]}
                        rules={[ { required: true, message: "Select the Communication Medium", }, ]}
                      >
                        <Checkbox.Group>
                          <Checkbox value="EMAIL">Email</Checkbox>
                          <Checkbox value="SMS">SMS</Checkbox>
                        </Checkbox.Group>
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col span={2}>
                  <Button
                    // disabled={removeButtonInvalid()}
                    //  disabled={form.getFieldValue(['users'])?.length<=1}
                    style={{ marginTop: "30px" }}
                    onClick={() => {
                      if (form?.getFieldValue(['users']).length === 1) {
                        form.setFieldsValue({ ["users"]: [] });
                        return;
                      }
                      setUserlength(!userLength);
                      if (userAgainEditData) {
                        userAgainEditData.splice(name, 1);
                        setApiCalled(!apiCalled);
                      }
                      remove(name);
                    }}
                  >
                    <MinusCircleOutlined />
                  </Button>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button
                // disabled={
                //   userOptionData?.length === 0 ||
                //   editRowData?.RESPONSIBLE_CRITERIA
                //     ? true
                //     : false
                // }
                disabled={addButtonInValid()}
                // disabled={userOptionData?.length === 0 ||!form.getFieldValue(['users'])?.[form.getFieldValue(['users'])?.length-1]||Object.keys(form.getFieldValue(['users'])?.[0]).length === 0}
                type="primary"
                onClick={() => {
                  // let tempUsers=form.getFieldValue(['users']);
                  // let tempUsers2=form.getFieldValue(['users'])?.filter(item=>item!==undefined);
                  // if(tempUsers?.length!==tempUsers2.length||Object.keys(tempUsers[0]).length === 0)
                  //   {
                  //     return;
                  //   }
                  add();
                  let tempObj = {};
                  if (userAgainEditData) {
                    userAgainEditData?.map(
                      (item) => (tempObj[item["USERID"]] = 1)
                    );

                    userOptionData = userOptionData?.filter((item) => {
                      if (!tempObj[item["label"]]) {
                        return item;
                      }
                    });
                  }
                }}
                block
                icon={<PlusOutlined />}
                style={{ width: "120px", marginTop: "30px" }}
              >
                Add User
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item
        wrapperCol={{
          offset: 10,
        }}
      >
        <Button
          className="addButton"
          onClick={() => {
            setEventModal(false);
            getAlertList();
          }}
        >
          Cancel
        </Button>
        <Button className="saveButton" type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoadParameterTab;
